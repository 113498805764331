import logger from '@knauf-group/ct-shared-nextjs/logger'
import queryString from 'query-string'

import { DOWNLOAD_CENTER_SERVICE_URL, DOWNLOAD_MULTIPLE_FILES_PREFIX } from '@/constants'

import { isEmpty } from '../plain/isEmpty'
import { removeDoubleSlashes } from '../plain/removeDoubleSlashes'

function formatDate(date: Date) {
  const padZero = (num: number) => num.toString().padStart(2, '0')

  const year = date.getFullYear()
  const month = padZero(date.getMonth() + 1) // Months are zero-based
  const day = padZero(date.getDate())
  const hours = padZero(date.getHours())
  const minutes = padZero(date.getMinutes())
  const seconds = padZero(date.getSeconds())

  // format: yyyyMMddHHmmSS
  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

export const generateLinkToDownload = (ids: string[]) => {
  if (isEmpty(ids)) return undefined

  let slugifiedFilename = DOWNLOAD_MULTIPLE_FILES_PREFIX
  try {
    slugifiedFilename = `${slugifiedFilename}${formatDate(new Date())}`
  } catch (error) {
    logger.error('Error while generating filename for download', error)
  }

  const query = queryString.stringify({ ids, filename: slugifiedFilename })
  return removeDoubleSlashes(`${DOWNLOAD_CENTER_SERVICE_URL}/archive?${query}`)
}
