import type { PropsWithChildren } from 'react'

import { Stack, Typography } from '@mui/material'

import { DIY_BANNER_HEIGHT, NAVIGATION_BANNER_HEIGHT, PRODUCT_BANNER_HEIGHT } from '@/constants'
import { useProduct } from '@/providers/p/ProductProvider'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'

type SectionLayoutProps = PropsWithChildren<{
  title: string
  id?: string
  dataCy?: string
}>

const EXTRA_PADDING_FOR_BETTER_POSITIONING = 8
const scrollMarginTopBase =
  PRODUCT_BANNER_HEIGHT + NAVIGATION_BANNER_HEIGHT + EXTRA_PADDING_FOR_BETTER_POSITIONING

export const SectionLayout = (props: SectionLayoutProps) => {
  const { children, title, id, dataCy } = props

  const { product } = useProduct()
  const { isDIY } = product

  const { isDesktop } = useBreakpoints()

  const scrollMarginTop =
    !isDIY || isDesktop ? scrollMarginTopBase : scrollMarginTopBase + DIY_BANNER_HEIGHT

  return (
    <Stack
      spacing={{ xs: 3, lg: 4 }}
      id={id}
      data-cy={dataCy}
      sx={{
        scrollMarginTop,
      }}
    >
      <Typography component="h2" variant="h4" data-cy={`${dataCy}-title`}>
        {title}
      </Typography>
      {children}
    </Stack>
  )
}
