import { useRouter } from 'next/router'

// the objects keys are the language codes
type UseGetDefaultFilesLanguage = <T extends object>(
  files: T,
) => { defaultFilesLanguage: string; filesLanguages: string[] }

export const useGetDefaultFilesLanguage: UseGetDefaultFilesLanguage = (files) => {
  const { locale } = useRouter()

  // check if files object has a locale key
  const filesLanguages = Object.keys(files)

  // if just 1 language, return it
  if (filesLanguages.length === 1) {
    return { defaultFilesLanguage: filesLanguages[0], filesLanguages }
  }

  const hasLanguageKey = filesLanguages.includes(locale)
  if (hasLanguageKey) {
    return { defaultFilesLanguage: locale, filesLanguages }
  }

  // TODO: logic for default language of chosen country
  // for example, if locale is nl-BE, we should take fr-BE if available, even if it's not the first key in the object

  // the first language for which files exist
  return {
    defaultFilesLanguage: filesLanguages[0],
    filesLanguages,
  }
}
