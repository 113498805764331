import { useState } from 'react'

import { Stack } from '@mui/material'

import { FilesDownloadSegment } from '@/components/ProductDetailsPage/FilesDownloadSegment'
import { FilesTable } from '@/components/ProductDetailsPage/FilesTable'
import { SECTIONS_MAP } from '@/constants'
import { SectionLayout } from '@/layouts/SectionLayout'
import { useProduct } from '@/providers/p/ProductProvider'
import type { CertificateFile, DownloadableFile, FilesDownloadSegmentProps } from '@/types'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useGetDefaultFilesLanguage } from '@/utils/frontend/useGetDefaultFilesLanguage'
import { useSelectedFiles } from '@/utils/frontend/useSelectedFiles'
import { useT } from '@/utils/frontend/useT'

// wrap certificates into custom category to reuse the same logic
const FILES_CATEGORY_NAME = 'certificates'
const { id } = SECTIONS_MAP.certificates
const dataCy = 'CertificatesSection'

const CertificatesSection = () => {
  const { product } = useProduct()
  const { certificateFiles } = product

  const { t } = useT({ keyPrefix: 'product' })

  const { trackDownload } = useProductDetailAnalyticsTracking()

  const { defaultFilesLanguage, filesLanguages } = useGetDefaultFilesLanguage(certificateFiles)
  const [certificatesLanguage, setCertificatesLanguage] = useState(defaultFilesLanguage)

  const certificatesByLang = certificateFiles[certificatesLanguage]

  // create an util object to reuse the same logic
  const certificatesByCategories = {
    [FILES_CATEGORY_NAME]: certificatesByLang,
  }

  const { selectedIds, isAllSelected, onSelection, onSelectAll, linkToDownload, allDocuments } =
    useSelectedFiles(certificatesByCategories)

  const onDownloadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const files = Object.values(certificatesByCategories)
      .flat()
      .filter((file) => selectedIds.includes(file.id))
    trackDownload(e.currentTarget.innerText, files, certificatesLanguage)
  }

  const filesDownloadSegmentProps: FilesDownloadSegmentProps<CertificateFile> = {
    files: certificatesByCategories,
    filesLanguage: certificatesLanguage,
    filesLanguages,
    setFilesLanguage: setCertificatesLanguage,
    linkToDownload,
    onDownloadClick,
    dataCy,
    selectedIds,
    onSelectAll,
    isAllSelected,
    totalFilesCount: allDocuments.length,
  }

  const onDownloadActionClick = <T extends DownloadableFile = DownloadableFile>(
    file: T,
    buttonLabel: string,
  ) => {
    trackDownload(buttonLabel, [file], certificatesLanguage)
  }

  return (
    <SectionLayout title={t('certificates-section-title')} id={id} dataCy={dataCy}>
      <Stack spacing={4}>
        <FilesDownloadSegment {...filesDownloadSegmentProps} />

        <FilesTable
          dataCy={`${dataCy}-FilesTable`}
          key={FILES_CATEGORY_NAME}
          files={certificatesByCategories[FILES_CATEGORY_NAME]}
          onDownloadActionClick={onDownloadActionClick}
          selectedIds={selectedIds}
          onSelection={onSelection}
        />
      </Stack>
    </SectionLayout>
  )
}

export default CertificatesSection
