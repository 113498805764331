import type { Locale } from 'next/router'

import type { DownloadableFile } from '@/types'

export const getFileInfo = (
  file: DownloadableFile,
  locale: Locale,
  pageTranslation: string,
) => {
  const date = new Date(file.updatedAt)
  const dateAndSize = `.${file.type} / 
  ${date.toLocaleDateString(locale, { month: 'short', year: 'numeric' })} / 
${file.size}`

  return file.numberOfPages! > 0
    ? `${dateAndSize} / 
  ${pageTranslation}`
    : ` ${dateAndSize}`
}
