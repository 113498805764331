import { useRouter } from 'next/router'

import { FileDownloadOutlined } from '@mui/icons-material'
import {
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import type { FilesTableFn } from '@/types'
import { getFileInfo } from '@/utils/frontend/getFileInfo'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useT } from '@/utils/frontend/useT'
import generateDownloadURL from '@/utils/plain/generateDownloadURL'

export const FilesTable: FilesTableFn = (props) => {
  const {
    files,
    title,
    onDownloadActionClick,
    dataCy,
    hasHeaderRow,
    selectedIds,
    onSelection,
  } = props

  const { locale } = useRouter()

  const { t } = useT({ keyPrefix: 'product.files' })

  const { isDesktop } = useBreakpoints()

  const rows = files.map((file) => ({ id: file.id, file } as const))

  return (
    <Table>
      {hasHeaderRow && (
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>{title}</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row) => {
          const isRowSelected = Array.isArray(selectedIds) && selectedIds.includes(row.id)
          return (
            <TableRow
              hover
              aria-checked={isRowSelected}
              selected={isRowSelected}
              key={row.id}
              sx={{
                backgroundColor: isRowSelected
                  ? (theme) => theme.palette.secondary.main
                  : 'none',
                '&:hover': {
                  backgroundColor: isDesktop ? 'text.primary' : 'none',
                },
                '&:last-child': {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isRowSelected}
                  onClick={() => onSelection(row.id)}
                />
              </TableCell>
              <TableCell>
                <Stack direction="column">
                  <Typography variant="body2bold">{row.file.name}</Typography>
                  <Typography variant="body2dense" color="text.primary">
                    {getFileInfo(
                      row.file,
                      locale,
                      t('table.pages', { count: row.file.numberOfPages }),
                    )}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">
                {isDesktop && (
                  <IconButton
                    color="secondary"
                    href={generateDownloadURL(row.file)}
                    download
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      onDownloadActionClick(row.file, `icon-${t('actions.download')}`)
                    }}
                  >
                    <FileDownloadOutlined data-cy={`${dataCy}-icon-download`} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
