import { Box, Button, Checkbox, Grid, MenuItem, Stack, TextField } from '@mui/material'

import Link from '@/components/Link'
import type { FilesDownloadSegmentFn } from '@/types'
import { useT } from '@/utils/frontend/useT'

export const FilesDownloadSegment: FilesDownloadSegmentFn = (props) => {
  const {
    filesLanguage,
    setFilesLanguage,
    filesLanguages,
    linkToDownload,
    onDownloadClick,
    selectedIds,
    isAllSelected,
    onSelectAll,
    dataCy,
    totalFilesCount,
  } = props

  const { t } = useT({ keyPrefix: 'product.files.actions' })

  const totalSelectedFilesCount = selectedIds?.length || 0

  const hasNoSelection = totalSelectedFilesCount === 0

  const onLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilesLanguage(event.target.value)
  }

  const downloadButtonLabel = (() => {
    if (isAllSelected) return `${t('download-all')} (${totalFilesCount})`
    if (hasNoSelection) return t('download')
    return `${t('download')} (${totalSelectedFilesCount})`
  })()

  const hasLink = linkToDownload != null

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Stack direction="row" spacing={2} alignItems="center">
            <Checkbox
              checked={isAllSelected}
              onClick={onSelectAll}
              color="secondary"
              // @ts-ignore
              inputProps={{ 'data-cy': `${dataCy}-checkbox` }}
            />
            <Button
              disabled={hasNoSelection}
              data-cy={`${dataCy}-Button`}
              onClick={onDownloadClick}
              {...(hasLink && {
                component: Link,
                target: '_blank',
                href: linkToDownload,
              })}
            >
              {downloadButtonLabel}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs>
          <TextField
            select
            value={filesLanguage}
            onChange={onLanguageChange}
            data-cy={`${dataCy}-filesLanguage-Select`}
          >
            {filesLanguages?.map((language) => (
              <MenuItem
                key={language}
                value={language}
                data-cy={`${dataCy}-filesLanguage-MenuItem-${language}`}
              >
                {language?.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  )
}
